import dynamic from 'next/dynamic';

const Layout = dynamic(() => import('@components/layouts'));
const LayoutBody = dynamic(() => import('@components/layouts/layout-body'));
const Header = dynamic(() => import('@components/core/header'));
const Footer = dynamic(() => import('@components/core/footer'));

const Landing = dynamic(() => import('@containers/home/components/landing'));
const OurPartner = dynamic(() => import('@containers/home/components/our-partner'));
const FeatureApp = dynamic(() => import('@containers/home/components/feature-app'));
const ManageAtEase = dynamic(() => import('@containers/home/components/manage-at-ease'));
const GetApp = dynamic(() => import('@containers/home/components/get-app'));

const HomeView = (props: any) => {
  return (
    <>
      <Layout {...props}>
        <LayoutBody>
          <Header />
          <Landing />
          <OurPartner />
          <FeatureApp />
          <ManageAtEase />
          <GetApp />
          <Footer />
        </LayoutBody>
      </Layout>
    </>
  );
};

export default HomeView;
